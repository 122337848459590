import { useMutation } from '@apollo/client';
import FingoNotificationPanel from '@fingo/lib/components/notifications/FingoNotificationPanel';
import { CHANGE_ASSIGNMENT_CESSION_TYPE } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ChangeAssignmentCessionType = () => {
  const { invoiceHash } = useParams();
  const { addAlert } = useSnackBars();

  const [assignmentCessiontTypeChangeResult, setAssignmentCessionTypeChangeResult] = useState('loading');

  const message = {
    loading: {
      header: '',
      body: '',
    },
    success: {
      header: '¡Estamos listos!',
      body: 'El cambio de tipo de cesión ha sido exitoso. Fingo cederá tu factura!',
    },
    error: {
      header: 'Algo salió mal',
      body: 'Por favor contacta a tu ejecutivo para informarle la situación!',
    },
  };

  const [changeToInternalCession, { loading }] = useMutation(
    CHANGE_ASSIGNMENT_CESSION_TYPE,
    { variables: {
      invoiceHash,
      status: 'internal',
    },
    onError: (err) => {
      setAssignmentCessionTypeChangeResult('error');
      addAlert({
        id: 'alert-assingment-cession-type-change',
        message: `Error: ${err.message}`,
        color: 'error',
        severity: 'error',
      });
    },
    onCompleted: () => {
      setAssignmentCessionTypeChangeResult('success');
    },
    },
  );

  useEffect(() => {
    changeToInternalCession();
  }, [changeToInternalCession]);

  return (
    <FingoNotificationPanel
      loading={loading}
      messageParagraph={message[assignmentCessiontTypeChangeResult]}
    />
  );
};
export default ChangeAssignmentCessionType;
