import { useMutation } from '@apollo/client';
import FingoNotificationPanel from '@fingo/lib/components/notifications/FingoNotificationPanel';
import { RUN_AUTOMATIC_CESSION } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const AutomaticCession = () => {
  const { automaticCessionConfigHash } = useParams();
  const { addAlert } = useSnackBars();

  const [automaticCessionResult, setAutomaticCessionResult] = useState('loading');

  const message = {
    loading: {
      header: '',
      body: '',
    },
    success: {
      header: '¡Estamos listos!',
      body: 'Las facturas ya fueron cedidas a Fingo!',
    },
    error: {
      header: 'Algo salió mal',
      body: 'Por favor contacta a tu ejecutivo para informarle la situación!',
    },
  };

  const [runAutomaticCession, { loading }] = useMutation(
    RUN_AUTOMATIC_CESSION,
    { variables: {
      automaticCessionConfigHash,
    },
    onError: (err) => {
      setAutomaticCessionResult('error');
      addAlert({
        id: 'alert-automatic-cession',
        message: `Error: ${err.message}`,
        color: 'error',
        severity: 'error',
      });
    },
    onCompleted: () => {
      setAutomaticCessionResult('success');
    },
    },
  );

  useEffect(() => {
    runAutomaticCession();
  }, [runAutomaticCession]);

  return (
    <FingoNotificationPanel
      loading={loading}
      messageParagraph={message[automaticCessionResult]}
    />
  );
};
export default AutomaticCession;
