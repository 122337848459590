import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useIsLogged } from '@fingo/lib/hooks';

const Landing = () => {
  const isLogged = useIsLogged();
  const location = useLocation();
  if (isLogged) {
    return <Redirect to="/app" />;
  }
  return (
    <Redirect to={{
      pathname: '/login',
      search: location.search,
    }}
    />
  );
};

export default Landing;
