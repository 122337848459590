import React, { useCallback, useMemo, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { GET_USER, ONBOARDING_AUTH } from '@fingo/lib/graphql';
import { useIsLogged } from '@fingo/lib/hooks';
import { getSerializedSearch, getCurrentSearchObject } from '@fingo/lib/helpers';

const OnboardingAuth = () => {
  const history = useHistory();
  const { location: { search } } = history;
  const urlParams = getCurrentSearchObject(history);
  const redirectPath = useMemo(() => (
    urlParams.invoiceId
      ? { pathname: 'app/lite-invoice', search, state: { referrer: 'supplier-onboarding' } }
      : { pathname: 'app/sales/factoring/available', search: getSerializedSearch({ company_id: urlParams.company_id }), state: { referrer: 'supplier-onboarding' } }
  ), []);
  const [onboardingAuth] = useMutation(
    ONBOARDING_AUTH,
    {
      onCompleted: ({ onboardingAuth: auth }) => {
        logIn(auth);
      },
      refetchQueries: [GET_USER],

    },
  );
  const activateAccount = useCallback(
    () => onboardingAuth(
      { variables: { activationCode: urlParams.activation_code, email: urlParams.email } },
    ),
  );
  useEffect(() => {
    activateAccount();
  }, []);
  const isLogged = useIsLogged();
  return (isLogged ? <Redirect to={redirectPath} /> : <Redirect to="/" />
  );
};

export default OnboardingAuth;
