import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { PaperHeader } from '@fingo/lib/components/headers';
import { GET_COMPANY_PREFERENCES } from '@fingo/lib/graphql';
import { groupIt } from '@fingo/lib/helpers';
import { useSelectedCompany, useFilteredQuery } from '@fingo/lib/hooks';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, Skeleton } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import ContactGrid from './ContactGrid';
import { CustomPagination } from '../../../components/tables';

const Contacts = (
  {
    queryContacts,
    queryVariables,
    title,
    relation,
  },
) => {
  const rowNumber = 15;
  const [page, setPage] = useState(0);
  const [globalFilter, setGlobalFilter] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const [snackBarType, setSnackBarType] = useState();
  const [switches, setSwitches] = useState({});
  const configureSnackBar = useCallback((type, message) => {
    setSnackBarMessage(message);
    setSnackBarType(type);
    setOpenSnackBar(true);
  }, []);
  const setFilter = useCallback((props) => {
    setGlobalFilter(props);
    setPage(0);
  }, []);
  const selectedCompany = useSelectedCompany();
  useQuery(GET_COMPANY_PREFERENCES, {
    onCompleted: (data) => {
      setSwitches(data.getCompanyPreferences);
    } });
  const { loading, refetch: refetchContacts, data: contactsData } = useFilteredQuery(
    queryContacts,
    {
      variables: { ...queryVariables,
        first: rowNumber,
        offset: page * rowNumber,
        globalFilter },
      skip: !selectedCompany,
      notifyOnNetworkStatusChange: true,
    },
  );
  const preparedData = contactsData
    ? groupIt(contactsData.getMasterEntities.edges.map((edge) => (edge.node))) : [];
  const pageNumber = contactsData?.getMasterEntities.totalPages || 0;
  const handleSearchChange = useCallback((value) => {
    setFilter(value);
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = useCallback((panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }, []);
  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);
  return useMemo(() => (
    <>
      <PaperHeader
        viewTitle={title}
        finder={{
          onFinderChange: handleSearchChange,
          searchPlaceHolder: `Buscar ${title.toLowerCase()}`,
          finderValue: globalFilter,
        }}
      />
      <Stack alignItems="center" width="100%" spacing={3}>
        {Object.keys(preparedData).map((letter) => (
          <Stack width="100%" key={letter} spacing={1}>
            <Typography
              color="primary"
              variant="h6"
            >
              {letter}
            </Typography>
            {preparedData[letter].map(({ rut, name, displayNationalIdentifier, id }) => (
              <Accordion
                elevation={0}
                key={rut}
                expanded={expanded === rut}
                onChange={handleChange(rut)}
                sx={{ bgcolor: '#f9f9f9', '&:before': { display: 'none' } }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  id="providers-list-header"
                  sx={{ px: 4 }}
                >
                  {loading ? <Skeleton variant="text" width="90%" /> : (
                    <>
                      <Typography variant="h2" fontWeight={700}>{name} &nbsp;&nbsp;</Typography>
                      <Typography variant="h2" ml={2}>{ displayNationalIdentifier }</Typography>
                    </>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <ContactGrid
                    company={id}
                    configureSnackBar={configureSnackBar}
                    refetchContacts={refetchContacts}
                    relation={relation}
                    switches={switches}
                    setSwitches={setSwitches}
                    expanded={expanded === rut}
                    contactType={CONTACT_TYPES.COLLECTION}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        ))}
      </Stack>
      <CustomPagination
        page={page}
        totalPages={pageNumber}
        onChangePage={handleChangePage}
      />
      <FingoSnackBar
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={snackBarMessage}
        severity={snackBarType}
      />
    </>
  ), [
    expanded,
    openSnackBar,
    snackBarMessage,
    snackBarType,
    page,
    pageNumber,
    switches,
    relation,
    preparedData,
    title,
    globalFilter,
  ]);
};

Contacts.propTypes = {
  queryContacts: PropTypes.objectOf(gql).isRequired,
  queryVariables: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  relation: PropTypes.string.isRequired,
};

export default Contacts;
