import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import {
  logOut,
  setSelectedFolio,
} from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { CircularProgress, Grid, Box } from '@mui/material';
import { ManagermasRegisterForm } from '../components/userActions';
import AceptaClickController from './AceptaClickController';
import SenegociaClickController from './SenegociaClickController';

const ExternalClickController = () => {
  const location = useLocation();
  // useStates
  const [loading, setLoading] = useState(true);
  const [showManagermasRegistration, setShowManagermasRegistration] = useState(false);
  const [managermasData, setManagermasData] = useState({});
  const [redirect, setRedirect] = useState(false);

  const managermasOnBoarding = (urlParams) => {
    window.history.pushState({}, document.title, '/external-login');
    setManagermasData(urlParams);
    setShowManagermasRegistration(true);
    setLoading(false);
  };

  // Controller that calls the right onboarding function
  useEffect(() => {
    const auxLoginParams = new URLSearchParams(location.search);
    logOut();
    const asyncUseEffect = async () => {
      if (auxLoginParams.has('token')) auxLoginParams.set('token', auxLoginParams.get('token').replaceAll(' ', '+'));
      if (auxLoginParams.has('folio')) setSelectedFolio(auxLoginParams.get('folio'));
      if (auxLoginParams) {
        if (auxLoginParams.get('from') === 'managermas') {
          managermasOnBoarding(auxLoginParams);
        }
      } else {
        setRedirect(true);
      }
    };
    asyncUseEffect();
  }, [location.search]);

  if (redirect) return <Redirect to="/" />;
  const auxLoginParams = new URLSearchParams(location.search);
  if (auxLoginParams.get('from') === 'acepta') return <AceptaClickController />;
  if (auxLoginParams.get('from') === 'senegocia') return <SenegociaClickController />;
  return (
    <Box>
      {(loading) && (
        <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={40} />
        </div>
      )}
      {showManagermasRegistration && (
        <Grid container>
          <Grid item xs={12} lg={12}>
            <ManagermasRegisterForm
              email={managermasData.get('user_email')}
              companyRut={managermasData.get('company_rut')}
              companyDomain={managermasData.get('company_domain')}
              token={managermasData.get('token')}
              companyId={managermasData.get('company_id')}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ExternalClickController;
