import React from 'react';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const NoOrderingOperationsError = () => (
  <>
    <Typography variant="h4" paragraph>
      No posees operaciones activas e históricas para mostrar
    </Typography>
    <Typography variant="body1" paragraph>
      En esta página se muestran las órdenes de compra que ya has operado con nosotros.
    </Typography>
    <Typography variant="body1" paragraph>
      Para evaluar tus órdenes de compra y operarlas con Fingo, revisa {' '}
      <Link component={RouterLink} to="/app/sales/ordering/available">tus órdenes de compra disponibles.</Link>
    </Typography>
  </>
);

export default NoOrderingOperationsError;
