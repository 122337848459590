import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { useMutation, useQuery } from '@apollo/client';
import { Tooltip, Grid, InputAdornment } from '@mui/material';
import { ADD_OR_EDIT_CONTACT, ERASE_CONTACT, CONTACTS } from '@fingo/lib/graphql';
import { useTranslation } from 'react-i18next';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import ValidationTextFieldDataGrid from '../../../components/inputs/ValidationTextFieldDataGrid';
import EmailSwitch from './EmailSwitch';

function EditToolbar(props) {
  const { setRows, setRowModesModel, id, company, switches, setSwitches, relation } = props;
  const handleClick = () => {
    setRows((oldRows) => [...oldRows, { id, name: '', phoneNumber: '', position: '', email: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Añadir
      </Button>
      <EmailSwitch
        switches={switches}
        setSwitches={setSwitches}
        company={company}
        relation={relation}
      />
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  company: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  switches: PropTypes.object.isRequired,
  setSwitches: PropTypes.func.isRequired,
  relation: PropTypes.string.isRequired,
};

export default function ContactGrid({
  company,
  configureSnackBar,
  relation,
  switches,
  setSwitches,
  expanded,
  contactType,
}) {
  const [rows, setRows] = useState([]);
  const { loading: loadingContacts } = useQuery(
    CONTACTS,
    {
      variables: { masterEntity_In: [company], contactType, first: 100, offset: 0 },
      onCompleted: ({ getContacts: { edges } }) => setRows(edges.map((contact) => contact.node)),
      skip: !expanded,
    },
  );
  const { t } = useTranslation();
  const [rowModesModel, setRowModesModel] = useState({});
  const [createOrEditContact] = useMutation(
    ADD_OR_EDIT_CONTACT,
    {
      onCompleted: (response) => {
        configureSnackBar(
          response.createOrEditContact.messageType,
          response.createOrEditContact.message,
        );
      },
      onError: (error) => {
        configureSnackBar(
          'error',
          error.message,
        );
      },
    },
  );
  const [eraseContact] = useMutation(
    ERASE_CONTACT,
    {
      onCompleted: (response) => {
        configureSnackBar(
          response.eraseContact.messageType,
          response.eraseContact.message,
        );
      },
      onError: (error) => {
        configureSnackBar(
          'error',
          error.message,
        );
      },
    },
  );
  const handleSaveClick = (id) => async () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };
  const handleProcessRowUpdate = React.useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      const response = await createOrEditContact({
        variables: { isNew: true,
          company,
          source: 'PROVIDED_BY_CLIENT',
          contactType,
          ...newRow },
      });
      const newContact = response.data.createOrEditContact.contact;
      setRows((oldRows) => ([...oldRows.filter(
        (row) => row.id !== newRow.id,
      ), newContact]));
      return newContact;
    },
    [createOrEditContact],
  );

  const handleProcessRowUpdateError = React.useCallback((error) => {
    configureSnackBar('error', error.message);
  }, []);

  const handleDeleteClick = (id) => () => {
    eraseContact(
      { variables: { id, company, contactType } },
    );
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 1, editable: true },
    {
      field: 'phoneNumber',
      headerName: 'Celular',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <>
          <b>
            +{t('Phone prefix')}
          </b>
          {params.value}
        </>
      ),
      renderEditCell: (params) => (
        <ValidationTextFieldDataGrid
          id={params.id}
          value={params.value}
          defaultValue={params.value}
          field={params.field}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                +{t('Phone prefix')}
              </InputAdornment>),
            disableUnderline: true,
            inputProps: { min: 0 },
          }}
          validationtype="phone"
        />
      ),
    },
    {
      field: 'position',
      headerName: 'Cargo',
      flex: 1,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <ValidationTextFieldDataGrid
          id={params.id}
          value={params.value}
          field={params.field}
          defaultValue={params.value}
          InputProps={{
            disableUnderline: true,
          }}
          validationtype="email"
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acción',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id] && rowModesModel[id].mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key="save"
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key="cancel"
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <Tooltip
            title="Borrar contacto"
            placement="left"
            key="delete"
          >
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              sx={{ padding: 0 }}
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];
  return (
    <Grid height="400px">
      <DataGrid
        rows={rows}
        loading={loadingContacts}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        editMode="row"
        rowModesModel={rowModesModel}
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        components={{
          Toolbar: (props) => (
            <EditToolbar
              {...props}
              id={rows.length ? Math.max(...rows.map((row) => row.id)) + 1 : 1}
            />
          ),
          Pagination: () => <></>,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel, company, switches, setSwitches, relation },
        }}
        getRowHeight={() => 'auto'}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Grid>
  );
}
ContactGrid.propTypes = {
  company: PropTypes.string.isRequired,
  configureSnackBar: PropTypes.func.isRequired,
  relation: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  switches: PropTypes.object.isRequired,
  setSwitches: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  contactType: PropTypes.string,
};

ContactGrid.defaultProps = {
  contactType: CONTACT_TYPES.COMMERCIAL,
};
