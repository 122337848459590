import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';

const EmailVerification = () => {
  const history = useHistory();
  const { encodedEmail, token } = useParams();
  const { addAlert } = useSnackBars();
  const [verifyEmail] = useMutation(VERIFY_EMAIL, { variables: { encodedEmail, token },
    onCompleted: () => {
      addAlert({
        id: 'mail-verified',
        message: 'Tu dirección de correo ha sido verificada con éxito!',
      });
      history.push('/login');
    },
    onError: () => {
      addAlert({
        id: 'mail-not-verified',
        message: 'Ocurrió algún error al tratar de verificar tu correo',
      });
      history.push('/login');
    } });
  useEffect(() => verifyEmail(), []);
  return <></>;
};
export default EmailVerification;
