import { Button, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { useHistory } from 'react-router-dom';

const SuccessDocumentSigning = () => {
  const history = useHistory();
  return (
    <Stack height="100%" width="100%" alignItems="center" justifyContent="flex-start" py={10}>
      <Card
        sx={{
          minHeight: 400,
          maxWidth: 500,
          width: '100%',
          borderRadius: 4,
        }}
      >
        <Stack
          height="40%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          borderRadius={4}
          bgcolor="primary.main"
          spacing={2}
        >
          <TaskAltOutlinedIcon sx={{ fontSize: 60 }} color="fingoWhite" />
          <Typography variant="h5" color="white">
            ¡Documento firmado con éxito!
          </Typography>
        </Stack>
        <Stack
          height="60%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          bgcolor="fingoWhite"
          spacing={4}
          px={8}
        >
          <Typography variant="body1" align="center">
            Recibirás un correo cuando el giro haya sido depositado en tu cuenta.
          </Typography>
          <Typography variant="body1" align="center">
            También puedes ver su estado en ”Operaciones activas e históricas”
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => history.replace('/app/sales/ordering/operations')}
          >
            Continuar
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
};

export default SuccessDocumentSigning;
